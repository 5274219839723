#publicProfile {
	margin: 0;
	padding: 0;

	.profileHeader {
		// height: 30vh;
		background-color: white;

		#profileImage {
			width: 150px;
		}
	}
}
