.dashboard-sidebar {
	flex: 1;
	border-right: 1px solid rgb(215, 215, 215);
	min-height: 100vh;
	background-color: white;

	@media screen and (max-width: 750px) {
		flex: 0;
	}

	.center {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: flex;
				align-items: center;
				cursor: pointer;
				padding: 15px;

				&:hover {
					background-color: #fef4cb;
				}
				.dashboard-icon {
					margin-left: 5%;
					font-size: 25px;
					color: #fad02c;
				}
				span {
					font-size: 20;
					font-weight: bold;
					color: #212529;
					margin-left: 5%;

					@media screen and (max-width: 750px) {
						display: none;
					}
				}
			}
		}
	}
	.bottom {
		display: flex;
		align-items: center;
		margin: 5%;
		padding: 15px;
		.dashboard-color-option {
			width: 25px;
			height: 25px;
			border-radius: 5px;
			border: solid 1px #fad02c;
			cursor: pointer;
			&:nth-child(1) {
				background-color: whitesmoke;
				margin-right: 5%;
			}
			&:nth-child(2) {
				background-color: #212529;
			}
		}
	}
}
