.topBanner {
	background-color: #fad02c;
	width: 100%;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 0.5rem 0.5rem 0.5rem;
	-webkit-transition: height 0.5s;
	transition: height 0.5s;
	&:hover {
		height: 3rem;
	}

	a {
		text-decoration: none;
		height: 100;
		display: flex;
		justify-content: center;
		align-items: center;
		div {
			p {
				// text-align: center;
				color: black;
				height: 100%;
				margin: 0 5px 0 0;
				text-align: center;

				&:hover {
					text-decoration: underline !important;
				}
			}
			img {
				width: 18px;
			}
		}
	}
}
