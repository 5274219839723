.dashboard-profile {
	display: flex;
	background-color: white;
	.dashboard-profile-container {
		flex: 6;
		.labels {
			font-size: 0.9em;
		}
	}
}
