.aboutpage .AboutBody {
	margin-top: 2%;
	color: white;
	padding: 60px;
	top: 0;
	width: 90%;
	padding: 2%;
}
.aboutpage .quote {
	font-size: 24px;
	text-align: center;
	margin-top: 1%;
	color: white;
	padding: 1%;
	width: 60%;
	min-width: 250px;
	font-weight: bold;
	font-size: medium;
}
.aboutpage blockquote::before {
	content: open-quote;
}
.aboutpage blockquote::after {
	content: close-quote;
}
.aboutpage blockquote {
	quotes: "“" "”" "‘" "’";
}


.aboutpage figcaption {
	font-size: smaller;
}
.aboutpage .contact {
	margin: 5vw;
}
.contact{
	position: relative;
}
@media screen and (max-width: 1300px) {
	.contact_image{
		display: none;
	}
}



