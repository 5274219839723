#root {
	margin: 0;
	font-family: "Poppins", sans-serif;
	font-smooth: always;
	background-image: linear-gradient(to left top, #bfb7ec 0%, #fef9d7 100%);
}

::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px grey;
	border-radius: 60px;
}
::-webkit-scrollbar-thumb {
	background: #ffc007;
	border-radius: 60px;
	height: 10px;
}

@keyframes shake {
	0% {
		margin-left: 0rem;
	}
	25% {
		margin-left: 0.5rem;
	}
	75% {
		margin-left: -0.5rem;
	}
	100% {
		margin-left: 0rem;
	}
}

button:active:hover {
	box-shadow: none;
	/* background-color: #ff0000; */
}

input:invalid,
textarea:invalid {
	animation: shake 0.2s ease-in-out 0s 2;
	/* box-shadow: 0 0 0.6rem #ff0000; */
	color: red !important;
}

.hover-img {
	background-color: #000;
	color: #fff;
	display: inline-block;
	margin: 8px;
	max-width: 320px;
	min-width: 240px;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 100%;
}

.centerDiv {
	display: flex;
	justify-content: center;
	align-items: center;
}

.invalid-input-p {
	color: red;
	margin: 0;
}

.required-asterisk {
	color: red;
}

.hover-img * {
	box-sizing: border-box;
	transition: all 0.45s ease;
}

.hover-img:before,
.hover-img:after {
	background-color: rgba(0, 0, 0, 0.5);
	border-top: 32px solid rgba(0, 0, 0, 0.5);
	border-bottom: 32px solid rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	content: "";
	transition: all 0.3s ease;
	z-index: 1;
	opacity: 0;
	transform: scaleY(2);
}

.hover-img img {
	vertical-align: top;
	max-width: 100%;
	backface-visibility: hidden;
}

.hover-img figcaption {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	align-items: center;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	line-height: 1.1em;
	opacity: 0;
	z-index: 2;
	transition-delay: 0.1s;
	font-size: 24px;
	font-family: sans-serif;
	font-weight: 400;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.hover-img:hover:before,
.hover-img:hover:after {
	transform: scale(1);
	opacity: 1;
}

.hover-img:hover > img {
	opacity: 0.7;
}

.hover-img:hover figcaption {
	opacity: 1;
}

.hover-underline-animation {
	/* display: inline-block; */
	position: relative;
	color: black;
}

.hover-underline-animation:after {
	content: "";
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: #fad02c;
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
	transform: scaleX(1);
	transform-origin: bottom left;
}
