.categorygrid {
	text-transform: uppercase;
	color: #656969;
	font-size: 1.3rem;
	line-height: 1.8rem;
}
.descript {
	padding-top: 1rem;
	margin-bottom: 1.8rem;
	color: #656969;
	font-size: 1.3rem;
	width: 100%;
}
#hor_separator {
	display: none;
}

.vl {
	position: absolute;
	left: -10%;
	top: -5%;
	border-left: 1px solid #a4a9a9;
	height: 700px;
}
@media screen and (max-width: 1240px) {
	.vl {
		height: 600px;
	}
}
@media screen and (max-width: 750px) {
	.vl {
		display: none;
	}
	#hor_separator {
		display: block;
	}
}
.subnav_link {
	color: black;
	text-decoration: none;
}
a.subnav_link:hover {
	cursor: pointer;
	color: #514b3b;
	border-bottom: 2px solid #514b3b;
	padding-bottom: 2px;
}
