.rise-button:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  transition: transform 500ms;
  box-shadow: 0 12px 16px 0 rgb(167, 165, 165);
}
.rise-button {
  position: relative;
}

.top-caption h1 {
  color: #3d3d3d;
  font-size: 4rem;
}
.parent_home_caroussel {
  position: relative;
}
@media screen and (max-width: 800px) {
  .chart_image {
    display: none;
  }
}
